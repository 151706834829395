<template>
  <div>
    <div
      class="row m-0"
      :class="{bg_light: background === 'light', 'p-5': background === 'light'}"
      v-if="loading"
    >
      <div class="col-12 mb-1 mb-md-2 p-0">
        Loading questionnaire...
      </div>
    </div>
    <div
      class="row m-0"
      :class="{bg_light: background === 'light', 'p-5': background === 'light'}"
      style="overflow-y: auto;"
      v-else
    >
      <div class="col-12 pb-3 text-left">
        <div
          class="col-12 mb-1 mb-md-2 p-0 pb-2"
          v-if="background === 'light'"
        >
          We'd really like to get to know you a little better, as this helps us to design a travel experience that is right 'up your alley'...
        </div>
      </div>
      <div class="col-12 pb-3 text-left">
        <div
          class="row m-0 pb-4"
          v-for="(q, index) of myQuestions"
          :key="index"
        >
          <div class="col-12 mb-1 p-0">
            {{q.text}}
          </div>
          <div class="col-12 m-0 p-0">
            <form-element
              :index="index"
              :disabled="saving || saved"
              :background="background"
              :inputData="questionAnswer(q.id)"
              :type="q.type"
              :optional="q.optional"
              :answers="q.answers"
              @updated="changeAnswer(q.id, $event)"
            >
            </form-element>
          </div>
          <div
            class="col-12 m-0 p-0 extra-text font5"
            v-if="q.conditionalExtraText && q.conditionalExtraTextAnswer === questionAnswer(q.id)"
          >
            <span v-html=q.conditionalExtraText></span>
          </div>
        </div>
        <!-- BEGINNING CHILDREN -->
        <div
          v-if="personal"
          class="row m-0 pb-4"
        >
          <div class="col-12 m-0 p-0">
            <div class="row m-0 pb-4">
              <div class="col-12 mb-1 p-0">
                How many children are you travelling with?
              </div>
              <div class="col-12 m-0 p-0">
                <form-element
                  :disabled="saving || saved"
                  :background="background"
                  type="number"
                  :min=0
                  :optional="true"
                  @updated="setChildrenGuests"
                  :inputData="numChildren"
                />
              </div>
            </div>
            <div
              class="row m-0 bordered"
              v-for="(child, index) of childrenGuests"
              :key="`child_${index}`"
            >
              <child-guest-questionnaire
                :background="background"
                :disabled="saving || saved"
                :index="index"
                :child="child"
              ></child-guest-questionnaire>
            </div>
          </div>
        </div>
        <!-- END CHILDREN -->
        <!-- BEGINNING ADULTS -->
        <div
          class="row m-0 pb-4"
          v-if="fromShortlink && primaryGuest"
        >
          <div class="col-12 mb-1 p-0">
            How many adults are you travelling with?
          </div>
          <div class="col-12 m-0 p-0">
            <form-element
              :disabled="saving || saved"
              :background="background"
              type="number"
              :min=0
              :optional="true"
              @updated="setAdultGuests"
            />
          </div>
        </div>
        <div
          class="row m-0 bordered"
          v-for="(a, index) of adultGuests"
          :key="`adult_${index}`"
        >
          <secondary-guest-questionnaire
            :background="background"
            :disabled="saving || saved"
            :index="index"
            @updated="setSecondaryGuest"
          ></secondary-guest-questionnaire>
        </div>
        <!-- END ADULTS -->
        <div
          class="row p-0 mb-3 mt-5"
          v-if="fromShortlink"
        >
          <div class="col-12 font6">
            <input
              type="checkbox"
              id="pp"
              :value="true"
              v-model="ppValue"
            >
            <span for="pp">
              By submitting your personal information, you give your consent for MORE Family Collection to use this information to fulfill your reservation and enhance your guest experience. Should we need to book accommodation or services which are facilitated by third parties, we will only share the relevant information pertaining to your booking. Should a service provider require a copy of your ID/Passport or Credit Card, we will share this confidential content with them via a secure password protected medium.<br />
              <!-- Our Privacy Policy may be accessed via <span class='link' @click="showModalTAndC = true">www.more.co.za/privacy-policy</span> -->
              Our Privacy Policy may be accessed via <a
                href="https://www.more.co.za/privacy-policy"
                target="_blank"
              >www.more.co.za/privacy-policy</a>
            </span>
          </div>
        </div>
      </div>

      <div
        class="col-md-12 p-0 text-center"
        :class="{'pb-5': !isDesktop}"
      >
        <button
          class="btn button-darkest"
          @click.stop="save"
          :disabled="!ppValue || saving || saved"
        >
          <span v-if="saving">Saving...</span>
          <span v-else-if="saved">Saved</span>
          <span v-else-if="fromShortlink">Save</span>
          <span v-else-if="!preferences">Save and go to preferences</span>
          <span v-else-if="!personal">Save and go to documents</span>
        </button>
      </div>
      <!-- <div class="col-md-12 p-0 text-center" :class="{'pb-5': !isDesktop}" v-else-if="!preferences">
        <button class="btn button-darkest" @click="save" :disabled="saving || saved">
          <span v-if="saving">Saving...</span>
          <span v-else-if="saved">Saved</span>
          <span v-else>Save and go to preferences</span>
        </button>
      </div>
      <div class="col-md-12 p-0 text-center" :class="{'pb-5': !isDesktop}" v-else>
        <button class="btn button-darkest" @click="save" :disabled="saving || saved">
          <span v-if="saving">Saving...</span>
          <span v-else-if="saved">Saved</span>
          <span v-else>Save and go to your documents</span>
        </button>
      </div> -->
    </div>
    <!-- <div class="row m-0">
      <div class="col p-0 montserrat_medium underline">
        Terms & Conditions
      </div>
      <div class="col p-0 text-right" :class="{ 'darkbg': background === 'dark' }">
        <label class="container">Accept
          <input type="checkbox" checked="checked">
          <span class="checkmark"></span>
        </label>
      </div>
    </div> -->
    <t-and-c
      v-if="showModalTAndC"
      @close="showModalTAndC=false"
    ></t-and-c>

  </div>
</template>
<script>
import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
const FormElement = () => import('@/components/FormElement')
const ChildGuestQuestionnaire = () =>
  import('@/components/ChildGuestQuestionnaire')
const SecondaryGuestQuestionnaire = () =>
  import('@/components/SecondaryGuestQuestionnaire')
const TAndC = () => import('../components/modals/TAndC')
// async function getUserIP (onNewIP) { //  onNewIp - your listener function for new IPs
//   // compatibility for firefox and chrome
//   const MyPeerConnection = window.RTCPeerConnection || window.mozRTCPeerConnection || window.webkitRTCPeerConnection
//   const pc = new MyPeerConnection({
//     iceServers: []
//   })
//   const noop = () => {}
//   const localIPs = {}
//   const ipRegex = /([0-9]{1,3}(\.[0-9]{1,3}){3}|[a-f0-9]{1,4}(:[a-f0-9]{1,4}){7})/g

//   const iterateIP = ip => {
//     console.log('ip, ip', ip)
//     if (!localIPs[ip]) onNewIP(ip)
//     localIPs[ip] = true
//   }

//   // create a bogus data channel
//   pc.createDataChannel('')

//   // create offer and set local description
//   const sdp = await pc.createOffer()
//   console.log('sdp', sdp.sdp.split('\n'))
//   const records = sdp.sdp.split('\n')
//   for (const record in records) {
//     console.log('r', record)
//     if (record.indexOf('candidate') < 0) return
//     console.log('record', record)
//     record.match(ipRegex).forEach(iterateIP)
//   }

//   pc.setLocalDescription(sdp, noop, noop)
//   // An error occurred, so handle the failure to connect

//   // listen for candidate events
//   pc.onicecandidate = ice => {
//     if (!ice || !ice.candidate || !ice.candidate.candidate || !ice.candidate.candidate.match(ipRegex)) return
//     ice.candidate.candidate.match(ipRegex).forEach(iterateIP)
//   }
// }

export default {
  name: 'GuestQuestionnaire',
  props: {
    background: {
      value: String,
      default: null
    },
    personal: {
      value: Boolean,
      default: true
    },
    preferences: {
      value: Boolean,
      default: true
    },
    fromShortlink: {
      value: Boolean,
      default: false
    },
    primaryGuest: {
      value: Boolean,
      default: false
    },
    enquiryId: {
      value: Number,
      default: null
    }
  },
  components: {
    ChildGuestQuestionnaire,
    FormElement,
    SecondaryGuestQuestionnaire,
    TAndC
    // FormElement, SecondaryGuestQuestionnaire, TAndC
  },
  data: () => ({
    adultGuests: [],
    childrenGuests: [],
    numChildren: null,
    ppValue: false,
    userIP: null,
    showModalTAndC: false,
    local: false,
    questions: [],
    myQuestions: null,
    loading: true,
    saved: false,
    saving: false
  }),
  computed: {
    ...mapGetters(['countries', 'isMobile', 'isTablet', 'profile', 'user']),
    isDesktop () {
      return !this.isMobile && !this.isTablet
    }
  },
  watch: {
    personal: {
      handler (newVal) {
        this.init()
      }
    },
    questions: {
      handler (newVal) {
        this.local = !!(
          this.myQuestions &&
          this.myQuestions.find(
            q => q.text === 'Nationality' && q.answer === 'South Africa'
          )
        )
        this.setMyQuestions()
      },
      deep: true
    }
  },

  async created () {
    if (this.fromShortlink) {
      let ip
      // try {
      //   getUserIP(ip => {
      //     console.log(`got ${ip}`)
      //   })
      // } catch (error) {
      //   console.log(error)
      // }
      try {
        const x = await fetch('https://api.ipify.org?format=json')
        const d = await x.json()
        ip = d.ip
      } catch (error) {
        console.log('NO ipify')
        ip = '192.168.1.1'
      }
      this.userIP = ip
    }
    this.init()
  },
  methods: {
    ...mapActions(['getCountries', 'getProfile', 'savePPAcceptance']),
    changeAnswer (qId, value) {
      if (typeof value !== 'undefined') {
        this.myQuestions.find(q => q.id === qId).answer = value
      }
    },
    async init () {
      this.loading = true
      this.saved = false
      this.saving = false
      this.ppValue = !this.fromShortlink // needed only if from shortlink
      let answers = []
      if (!this.fromShortlink && this.user) {
        try {
          const { data } = await this.$api.questionnaire.getAnswers()
          answers = data
        } catch (error) {
          console.error(error)
        }
      }
      const resQuestion = await this.$api.questionnaire.allQuestions()
      const questions = resQuestion.data.sort((a, b) =>
        a.order < b.order ? -1 : 1
      )
      await this.getCountries()
      this.questions = []
      for (const q of questions) {
        if (q.text === 'Nationality') {
          q.answers = [...this.countries].map(c => {
            c.text = c.name
            c.value = c.name
            return c
          })
        }
        const oldAnsws = answers.find(
          a => q && a.guestQuestionnaireQuestionId === q.id
        )
        let value = null
        if (oldAnsws) {
          value = oldAnsws.answer
        }
        q.answer = value
        this.questions.push(q)
      }
      this.setMyQuestions()
      if (this.profile) {
        const latestEnquiryId = [...this.profile.enquiriesIds].sort((a, b) =>
          a > b ? -1 : 1
        )[0]
        this.childrenGuests = JSON.parse(
          JSON.stringify(
            [...this.profile.children].filter(c =>
              c.enquiriesIds.includes(latestEnquiryId)
            )
          )
        )
        this.numChildren = this.childrenGuests.length
      }
      this.loading = false
    },
    questionAnswer (qId) {
      return this.questions.find(q => q.id === qId).answer
    },
    async save () {
      this.saving = true
      try {
        let i = 1
        const answers = this.myQuestions.map(q => ({
          value: q.answer,
          questionId: q.id,
          order: i++
        }))
        await this.$api.questionnaire.saveQuestions({
          answers,
          shortlink: this.$route.params.code,
          adultGuests: this.adultGuests,
          childrenGuests: this.childrenGuests
        })
        if (this.fromShortlink) {
          await this.savePPAcceptance({
            ip: this.userIP,
            shortlink: this.$route.params.code
          })
          this.saving = false
          this.saved = true
          this.$emit('savedSL')
        } else {
          this.saving = false
          this.saved = true
          if (this.personal && this.user) {
            try {
              await this.getProfile()
            } catch (error) {}
          }
          if (!this.preferences) {
            this.$emit('saved', 'preferences')
          }
          if (!this.personal) {
            this.$emit('saved', 'docs')
          }
        }
      } catch (error) {
        console.error(error)
        this.saving = false
      }
    },
    setAdultGuests (adults) {
      if (!isNaN(adults) && parseInt(adults) >= 0) {
        const i = parseInt(adults)
        if (i < this.adultGuests.length) {
          this.adultGuests.splice(i, this.adultGuests.length - i)
        } else if (i > this.adultGuests.length) {
          while (this.adultGuests.length < i) {
            this.adultGuests.push({
              name: null,
              surname: null,
              email: null
            })
          }
        }
      }
    },
    setChildrenGuests (children) {
      if (!isNaN(children) && parseInt(children) >= 0) {
        const i = parseInt(children)
        if (i < this.childrenGuests.length) {
          this.childrenGuests.splice(i, this.childrenGuests.length - i)
        } else if (i > this.childrenGuests.length) {
          while (this.childrenGuests.length < i) {
            this.childrenGuests.push({
              name: null,
              surname: null,
              dob: null,
              dietaryRequirementsAllergies: null,
              favouriteDelights: null,
              favouriteAnimal: null
            })
          }
        }
      }
    },
    setMyQuestions () {
      Vue.set(
        this,
        'myQuestions',
        this.questions
          ? this.questions.filter(q => {
            const conditionNotPersonal = !this.personal
              ? q.profile === false
              : true
            if (!conditionNotPersonal) {
              return false
            }
            const conditionNotPreferences = !this.preferences
              ? q.profile === true
              : true
            if (!conditionNotPreferences) {
              return false
            }
            if (q.internationalOnly && this.local) {
              return false
            }
            if (q.conditionalQuestionId) {
              const conditionalQuestion = this.questions.find(
                q1 => q1.id === q.conditionalQuestionId
              )
              return (
                conditionalQuestion &&
                  conditionalQuestion.answer === q.conditionalQuestionAnswer
              )
            } else {
              return true
            }
          })
          : []
      )
    },
    setSecondaryGuest (data) {
      const adult = this.adultGuests[data.index]
      for (const [k, v] of Object.entries(data.details)) {
        adult[k] = v
      }
    }
  }
}
</script>
<style scoped>
.extra-text {
  font-style: italic;
}
.link {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
